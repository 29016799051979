import React from "react";
import { connect } from "react-redux";
import TopBanner from "./topBanner";
import IntroService from "./introService";
import IntroFeatures from "./introFeatures";
import IntroRecomend from "./introRecomend";
import IntroAPI from "./introAPI";
import CardLegal from "./cardLegal";
import CardComp from "./cardComp";
import NoticeModal from "../parts/modal/noticeModal";
import ISOBanner from "./isoBanner";
import _ from "lodash";
import ResponsiveMedia from "utils/responsive_media";
import IntroOnPremise from "./introOnPremise";

const Main = ({ noticeClose, noticeCloseWhileADay, features, features_card, recomend, api, iso, comp_card, on_premise }) => {
    const { isMobile } = ResponsiveMedia();

    return (
        <>
            {process.env.GATSBY_NOTICE_MODAL === "on" && !noticeClose && !noticeCloseWhileADay && <NoticeModal />}
            <TopBanner />
            <IntroService />
            <IntroFeatures desc={features} card={features_card} />
            <IntroRecomend desc={recomend} />
            <IntroAPI desc={api} />
            <IntroOnPremise desc={on_premise} />
            <CardLegal />
            <ISOBanner data={iso} />
            <CardComp data={comp_card} chunk={isMobile ? 6 : 9} />
        </>
    );
};

export default connect(
    (state) => ({
        noticeClose: state.noticeModal.close,
        noticeCloseWhileADay: state.publicData.close_while_a_day
    }),
    null
)(Main);
